<template>
  <v-container class="">
    <v-row class="text-center">
      <v-col>
        <v-img
            :src="require('@/assets/images/drawSVGs/wellDone.svg')"
            max-width="200px"
            class="d-inline-flex mt-16 mb-16"
            style="transform: scaleX(-1);"/>
        <h1 class="text-h4">Vielen Dank, dass Du dich für WeddingFlow entschieden hast!</h1>
        <p class="text-subtitle-1">Dein Abo und deine Zahlungsmethode kannst du in den Einstellungen verwalten.</p>

      </v-col>
    </v-row>
    <v-row class="text-center justify-center">
      <v-col cols="10" md="5">
        <v-progress-linear
            indeterminate
            height="8px"
            rounded
            color="primary"
        ></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>


export default {
  name: "CreatedPayment",
  computed: {},
  mounted() {
    setTimeout(()=>{
      this.$router.push('/')
    },5000)
  }
}
</script>
